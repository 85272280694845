// App.js
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter as Router, Routes, Route} from 'react-router-dom'
import Navbar from './Components/Navbar/Navbar2';
import Footer from './Components/Footer/Footer';
import Login from './Pages/Auth/Login';
import Signup from './Pages/Auth/Signup';
import Home from './Pages/Home/Home';
import API from './Pages/API/API';
import UpdateAPI from './Pages/API/updateAPI';
import ProtectedRoute from './Components/protectedRoute/protectedRoute';

function App() {
  // Assuming you have some state to track authentication status

  return (
    <div className="App">
      <Router>
        <Navbar />
        <Routes>
          <Route element={<Home />} path="/" />
          <Route element={<Login />} path="/login" />
          <Route element={<Signup />} path="/signup" />
          <Route element = {<ProtectedRoute/>} >
            <Route element={<API />} path="/api" />
            <Route element={<UpdateAPI />} path="/updateAPI" />
          </Route>
        </Routes>
        <Footer />
      </Router>
    </div>
  );
}

export default App;
