import React, { useState } from "react";
import { Form, Button, Container, Row, Col } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";


export default function Signup()  {

    const [validated, set_Validated] = useState(false);
    const navigate = useNavigate();
    const [form_Data, set_Form_Data] = useState({
      updatedAPI : "",
      confirmUpdatedAPI : ""
    });

    const submitFn = (event) => {
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        }
        set_Validated(true);

        // call the axios functions here for the other functionalities
        alert("Api updated")
        navigate('/api')
    };

    const chngFn = (event) => {
        const { name, value } = event.target;
        set_Form_Data({
            ...form_Data,
            [name]: value,
        });
    };
    
    return (
      <>
      <div className="about-title">
            <h2>Update API</h2>
      </div>
        <Container className="mt-1">
            <Row>
                <Col
                    md={{
                        span: 6,
                        offset: 3,
                    }}
                >
                    <Form noValidate validated={validated} onSubmit={submitFn}>
                        <Form.Group controlId="API">
                            <Form.Label className="text-center">API</Form.Label>
                            <Form.Control
                                type="text"
                                name="API"
                                value={form_Data.API}
                                onChange={chngFn}
                                required
                                isInvalid={
                                    validated
                                }
                            />
                            <Form.Control.Feedback type="invalid">
                                Please enter the API
                            </Form.Control.Feedback>
                        </Form.Group>
                        <br/>
                        <Form.Group controlId="confirmAPI">
                            <Form.Label>Confirm API</Form.Label>
                            <Form.Control
                                type="text"
                                name="confirmAPI"
                                value={form_Data.confirmAPI}
                                onChange={chngFn}
                                pattern={form_Data.API}
                                required
                                isInvalid={
                                    validated &&
                                    form_Data.confirmAPI !== form_Data.API
                                }
                            />
                            <Form.Control.Feedback type="invalid">
                                APIs do not match.
                            </Form.Control.Feedback>
                        </Form.Group>
                        <br/>
                        <div class="col-md-12 text-center">
                          <Button variant="outline-primary" type="submit">Submit</Button>
                          <br/><br/>
                        </div>
                    </Form>
                </Col>
            </Row>
        </Container>
        </>
    );
};