import React from 'react';
import { Route, Navigate, Outlet } from 'react-router-dom';

// Example function to verify JWT token
const verifyToken = () => {
    // Check if token exists in localStorage and is not empty
    return localStorage.getItem('token') !== null && localStorage.getItem('token').trim() !== '';
};

// ProtectedRoute component to handle protected routes
const ProtectedRoute = () => (
      verifyToken() ? <Outlet/> : <Navigate to='/login'/>
);

export default ProtectedRoute;
