import React, { useState } from 'react';
import { Card, Button, Row, Col } from 'react-bootstrap';

const DataCard = ({ data, onFeedbackSubmit }) => {
    const [rating, setRating] = useState(null);

    const escapeRegExp = string => string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
  
    const handleRating = (value) => {
      setRating(value);
  
      onFeedbackSubmit({
        text: data.context,
        rating: value === 'thumbs-up' ? 'positive' : 'negative',
        index: data.index,
      });
    };

    // const highlightText = (text, highlightedText) => {
    //     let uncleaned = [];
    //     for (let i of highlightedText) {
    //     if (i.slice(0, 2) === "##") {
    //         let t = uncleaned.pop() + i.slice(2);
    //         uncleaned.push(t);
    //     } else {
    //         uncleaned.push(i);
    //     }}

    //     let cleanedHighlights = [...new Set(uncleaned)];
    //     if (cleanedHighlights && cleanedHighlights.length > 0) {
    //         const wordsToHighlight = cleanedHighlights.map(word => word.toLowerCase());
    //         const escapedWordsToHighlight = wordsToHighlight.map(escapeRegExp);
    //         const pattern = escapedWordsToHighlight.join('|');
    //         const parts = text.split(new RegExp(`(${pattern})`, 'gi'));
    //         const filteredParts = parts.filter(item => item !== undefined && item !== null && item !== "");

    //         console.log(filteredParts)
        
    //         return (
    //           <span>
    //             {filteredParts.map((part, index) =>
    //               wordsToHighlight.includes(part.toLowerCase().trim()) ? (
    //                 <span key={index} style={{ backgroundColor: 'yellow' }}>
    //                   {part}
    //                 </span>
    //               ) : (
    //                 <span key={index}>{part}</span>
    //               )
    //             )}
    //           </span>
    //         );
    //       }
    //       return <span>{text}</span>;
    // }

    const highlightText = (text, highlightedText) => {
      let uncleaned = [];
      for (let i of highlightedText) {
          if (i.length > 2 && i.slice(0, 2) === "##") {
              let t = uncleaned.pop() + i.slice(2);
              uncleaned.push(t);
          } else {
            uncleaned.push(i);
          }
      }
  
      let cleanedHighlights = [...new Set(uncleaned)];
      if (cleanedHighlights && cleanedHighlights.length > 0) {
          const wordsToHighlight = cleanedHighlights.map(word => escapeRegExp(word.toLowerCase()));
          const pattern = '\\b(?:' + wordsToHighlight.join('|') + ')\\b';
          const parts = text.split(new RegExp(`(${pattern})`, 'gi'));
          const filteredParts = parts.filter(item => item !== undefined && item !== null && item !== "");
  
          console.log(filteredParts)
  
          return (
              <span>
                  {filteredParts.map((part, index) =>
                      wordsToHighlight.includes(part.toLowerCase().trim()) ? (
                          <span key={index} style={{ backgroundColor: 'yellow' }}>
                              {part}
                          </span>
                      ) : (
                          <span key={index}>{part}</span>
                      )
                  )}
              </span>
          );
      }
      return <span>{text}</span>;
  }
  

    return (
        <>
        <Card>
      <Card.Body>
        <Row>
          <div className="col-8">
          <Card.Title>{data.Title}</Card.Title>
          </div>
          <div className="col-2">
            <Button
              variant={rating === 'thumbs-up' ? 'success' : 'outline-success'}
              onClick={() => handleRating('thumbs-up')}
              disabled={rating === 'thumbs-up'}
            >
              👍
            </Button>
          </div>
          <div className="col-2">
            <Button
              variant={rating === 'thumbs-down' ? 'danger' : 'outline-danger'}
              onClick={() => handleRating('thumbs-down')}
              disabled={rating === 'thumbs-down'}
            >
              👎
            </Button>
          </div>
        </Row>
        <Card.Text>{highlightText(data.Context, data.Highlights)}</Card.Text>
      </Card.Body>
    </Card>
    <div className='comment-box'>
      <p><b>Search Keyword : </b>{data.Key}</p>
    </div>
    </>

)};
export default DataCard;