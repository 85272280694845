import React, {useState} from 'react';
import './Home.css';

export default function Home() {
    return (
        <div className="home-container">
            <header>
                <h1>Welcome to GearUp</h1>
                <p>
                    GearUp is an innovative collaboration between the Artificial Intelligence Institute 
                    at South Carolina and the University Libraries at the University of South Carolina. 
                    The project aims to streamline the process of conducting systematic reviews by 
                    automating query development and translation.
                </p>
            </header>
            <section>
                <h2>Getting Started</h2>
                <p>Begin by signing up to obtain a personalized API key from PubMed. Once registered, log in to access and interact with the system.</p>
            </section>
            <section>
                <h3>How to Obtain a PubMed API Key</h3>
                <ol>
                    <li>Visit PubMed and log in to your NCBI account. If you do not have an account, create one through the “Sign up” option.</li>
                    <li>Navigate to your account settings and locate the “API Key Management” section.</li>
                    <li>Generate, copy, and securely save your API key for future use in the GearUp system.</li>
                </ol>
            </section>
            <section>
                <h2>Using the System</h2>
                <p>After registration, log in using your email, password, and API key. This will grant you access to the query interface of the system.</p>
            </section>
            <section>
                <h3>Query Interface</h3>
                <p>Enter your query and a set of sentinel articles to receive the top retrieved articles. For each article, you have the option to provide feedback:</p>
                <ul>
                    <li>Thumbs up or thumbs down to indicate the relevance of the article.</li>
                    <li>Add specific feedback for further refinement and improvements.</li>
                </ul>
                <div className="button-container">
                    {/* These buttons are shown for illustrative purposes and are disabled */}
                    <button disabled title="Submits the feedback for the current article">Submit Feedback</button>
                    <button disabled title="Loads the next set of articles for review">Next Query</button>
                </div>
                <p className="button-explanation">
                The "Submit" button is designed to finalize and save all the inputted responses, including thumbs up, thumbs down, and any text feedback on the retrieved articles. After pressing the "Submit" button, the "Next Query" button will become available. This button enables users to initiate a new search query within the system.
                </p>
            </section>
            <section className="resources">
                <h3>Resources</h3>
                <p>For a deeper understanding of GearUp, consider reviewing our <a href="https://ojs.aaai.org/index.php/AAAI/article/view/30577" target="_blank" rel="noopener noreferrer">Demo Paper</a> and watching the <a href="https://youtu.be/yLjBAqB-mYI?si=_uvhQM_gK0zgdcmv" target="_blank" rel="noopener noreferrer">Demo Video</a>.</p>
            </section>
        </div>
    );
}
