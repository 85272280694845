import React, {useState} from 'react';
import { Form,InputGroup, FormControl, Button, Col, Spinner, Row} from 'react-bootstrap';
import RangeSlider from 'react-bootstrap-range-slider';
import { useNavigate} from 'react-router-dom';
import {FaInfoCircle} from 'react-icons/fa';
import Table from 'react-bootstrap/Table';
import './API.css';
import Card from './card2';
import axios from 'axios';
import API_URL from '../../config';

export default function Home() {
    //const API_URL = "http://129.252.131.33:5000/"
    //const API_URL = "http://localhost:5000/" // For testing environment only
    const [data, setData] = useState([]);
    const navigate = useNavigate();

    const [timestamp, setTimeStamp] = useState('');

    const [formData, setFormData] = useState({
        prompt: '',
        sentinel: '',
        email: 'email@email.com'
    });

      const additionalInfo = ["Using the MesH hierarchy to extend the vocabulary. ", "Expansion of the query using domain-specific masked language models. ", 
      "FAISS’s vector search infrastructure to retrieve top articles from PubMed. "];

      const moreInfo = ["Leveraged the MesH hierarchy to enhance vocabulary, applying a hierarchy cut-off at level 2. Relevant terms filtered using ClinicalBERT embeddings for precision.", 
      "Utilized domain-specific masked language models like ClinicalBERT for query expansion, introducing new, relevant concepts to enrich the original query.", 
      "Employed sentence transformer embeddings and FAISS vector search to efficiently retrieve the top 5 articles from PubMed, closely aligned with the query."];

     //const Similarities = ["83","78","67"];
     const [submitPressed, setSubmitPressed] = useState(false);

      const loadingScreenStyle = {
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        background: 'rgba(255, 255, 255, 0.7)',
        zIndex: 1000,
        flexDirection: 'column',
      };
      
      
    const [showLoading, setShowLoading] = useState(false);
    const [cardsVisible, setCardsVisible] = useState(false);

    const [completedMessages] = useState([ '✅   Completed Vocabulary Extension', '✅   Completed Query Expansion', '✅   Retrieved Relevant Articles']);
     
    const startProcessing = async () => {
      const timestamp = new Date().getTime();
      const jwtToken = localStorage.getItem('token');
        try {

            setShowLoading(true);
            const response = await axios.get(API_URL+'get_answers', {
              params: { query: formData.prompt, sentinel : formData.sentinel, timestamp : timestamp, token : jwtToken}, 
              headers: {
                'Content-Type': 'application/json',
              },
            });

            setData(response.data);
            
            setTimeStamp(timestamp)
            if(response.status === 200){
              setShowLoading(false);
              setCardsVisible(true);
            }else if (response.status === 205){
              setShowLoading(false);
              alert("Unauthorized search")
              localStorage.removeItem('token')
              navigate('/login');
              //window.location.replace('/login')
          }
          } catch (error) {
            console.error('Error fetching data:', error);
            setShowLoading(false);
            alert(error)
            alert("Error occured while fetching the data. Please try again.");
          }
    };

    const [sentiment, setSentiment] = useState(['neutral', 'neutral', 'neutral','neutral','neutral']);


    // For the user comments

  const [otherInputs, setOtherInputs] = useState(['','','','','']); // Increase this by the number of responses you plan to see
  const [concepts, setConcepts] = useState(['','','','','']);
  const [commentInputs,setCommentInputs] = useState(['','','','','']);
  const [finalInputs,setFinalInputs] = useState('');
  const [relevancy_rating,setRelevancyRating] = useState();

  const handleInputChange = (index, event) => {
    let newCommentInputs = [...commentInputs];
    newCommentInputs[index] = event.target.value;
    setCommentInputs(newCommentInputs);
  };

  const handleFinalInputChange = (event) => {
    setFinalInputs(event.target.value);
  };

  const handleOtherInputChange = (index, event) => {
    let newOtherInputs = [...otherInputs];
    newOtherInputs[index] = event.target.value;
    setOtherInputs(newOtherInputs);
  };

  //const [relevantConcept, setRelevantConcept] = useState([true,true,true]);
  const relevantConcept = false;


    const handleFeedbackSubmit = (feedbackData) => {
      // Update the sentiment array at the specified index
      const updatedSentiment = [...sentiment];
      updatedSentiment[feedbackData.index] = feedbackData.rating === 'positive';
      setSentiment(updatedSentiment);

    };

    
    const submitFeedback = async () => {
      //alert(relevancy_rating)
      const jwtToken = localStorage.getItem('token')
      //alert(sentiment);
      const concept_final = concepts + ',' + otherInputs
      try {
          const reply = await axios.post(API_URL + 'submit_response', {token : jwtToken, timestamp: timestamp, sentiments: sentiment, concepts: concept_final, comments: commentInputs, relevancy_rating: relevancy_rating, why_relevancy: finalInputs});
          alert(reply.data);
          setSubmitPressed(true);
      } catch (error) {
          console.error('Error submitting feedback:', error);
          alert('Error submitting feedback');
      }
    }

    const handleRefresh = () => {
      window.location.reload();
    };
  

    const completedInfo = ["Message 1", "Message 2", "Message 3"];
    const [infoContent, setInfoContent] = useState(Array(completedInfo.length).fill(''));
    const tableInfo = ["Shows the similarity (cosine similarity)","between the embeddings of the retrieved articles and the sentinel articles."]
    const [tableInfoContent, setTableInfoContent] = useState('');

    const handleInfoHover = (index) => {
      // Set the content for the specific index
      setInfoContent((prevContent) => {
        const newContent = [...prevContent];
        newContent[index] = (<> {additionalInfo[index]}{' '} <span style={{ fontSize: 'small' }}>(click info for more)</span> </> );;
        return newContent;
      });
    };

    const [showOtherInput, setShowOtherInput] = useState([false,false,false]);

    const handleInfoClick = (index) => {
      // Set the content for the specific index
      setInfoContent((prevContent) => {
        const newContent = [...prevContent];
        newContent[index] = additionalInfo[index] + ' ' +moreInfo[index];
        return newContent;
      });
    };

    const handleInfoLeave = (index) => {
      // Clear the content when leaving
      setInfoContent((prevContent) => {
        const newContent = [...prevContent];
        newContent[index] = '';
        return newContent;
      });
    };


    const handleInfoHover2 = () => {
      // Set the content for the specific index
      setTableInfoContent(
        <>
          {tableInfo[0]} <span style={{ fontSize: 'small' }}>(click info for more)</span>
        </>
      );
    };

    const handleInfoClick2 = () => {
      // Set the content for the specific index
        setTableInfoContent(`${tableInfo[0]} ${tableInfo[1]}`);
    };

    const handleInfoLeave2 = () => {
      // Clear the content when leaving
      setTableInfoContent('');
    };



    const handleConceptChange = (index, event) => {
      const id = event.target.id; // Assuming id of the checkbox is the same as the concept label
      const isChecked = event.target.checked;

      // Update the concepts array based on the checkbox checked state
      const updatedConcepts = [...concepts];
      if (isChecked){
      
        updatedConcepts[index] = updatedConcepts[index] + '-' + id
        updatedConcepts[index] = updatedConcepts[index].replace(/^[-]/, '')
      }
      //updatedConcepts[index] = isChecked ? id : ''; // Set to id if checked, otherwise empty string
      
      /*if (id === "Relevant Concept"){
        alert(index)
        relevantConcept[index] = event.target.checked;
        alert(relevantConcept)
        updatedConcepts[index] = '';
      }*/
  
      // Update state with the new concepts array
      setConcepts(updatedConcepts);
      //alert(concepts)
    };
    
    return (
      <>
      <div className="about-title">
            <h2>GEAR UP</h2>
      </div>
      <div className="about-about">
            <p>
            Please add your prompt here to generate responses from GEAR-Up. 
            
            If any mistakes are made, please aid us in understanding the mistakes through the feedback box provided. 
            
            After submitting feedback, you will see a New Query button to start a new query. 
            Thank you for your interest in using our system.
            </p>
      </div>
      <div className='card2'>
        <InputGroup>
          <FormControl id ='prompt' 
          placeholder="Please type your research question here."
          value={formData.prompt}
          onChange={(e) => setFormData({ ...formData, prompt: e.target.value })}
           />
        </InputGroup>
        <br/>
        <Form>
              <Form.Group className="mb-3" >
              <Form.Label size="lg">Sentinel Article</Form.Label>
                <Form.Control
                  id = 'sentinel'
                  as="textarea"
                  rows={3}
                  value={formData.sentinel}
                  onChange={(e) => setFormData({ ...formData, sentinel: e.target.value })}
                  placeholder="Please include a relevant citation to an article that matches your research question and should be included in your review. Input Format: Title1; Title2."
                />
              <br/>
            </Form.Group>
          </Form>
          <div className="centered-button-container">
          {/* <Button variant="outline-primary" onClick={autoFill1}>AutoFill 1</Button> */}
          &emsp;
          <Button variant="outline-primary" onClick={startProcessing}>Submit Query</Button>
          &emsp;
          {/* <Button variant="outline-primary" onClick={autoFill2}>AutoFill 2</Button> */}
          </div>
      </div>
      
      {showLoading ? (
        <div style={loadingScreenStyle} className="loading-screen">
          <Spinner animation="border" role="status">
            <span className="sr-only">Loading...</span>
          </Spinner>
        </div>
      ) : cardsVisible ? (
        
      <div className='response-box'>
          <div className='comment-box'>
            

          <Table className='tabulated-data'>
            <thead>
              <tr>
                <th>#
                <button
                  className='info-buttons'
                  onMouseEnter={() => handleInfoHover2()}
                  onMouseLeave={() => handleInfoLeave2()}
                  onClick = {()=> handleInfoClick2()}
                >
                  <FaInfoCircle />
                </button>
                <span className="info-content">{tableInfoContent}</span>
                </th>
                
                
                <th>Retrieved Article 1</th>
                <th>Retrieved Article 2</th>
                <th>Retrieved Article 3</th>
                <th>Retrieved Article 4</th>
                <th>Retrieved Article 5</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Avg. Sentinel Article Score</td>
                {data.map((item, index) => (
                    <td key={index}>{Number(item.Score.toFixed(5))}</td>
                ))}
              </tr>
            </tbody>
          </Table>
          </div>
        <div className='comment-box'>
              {completedMessages.map((message, index) => (
              <p key={index}>
                {message}{' '}
                <button
                  className='info-buttons'
                  onMouseEnter={() => handleInfoHover(index)}
                  onMouseLeave={() => handleInfoLeave(index)}
                  onClick = {()=> handleInfoClick(index)}
                >
                  <FaInfoCircle />
                </button>
                <span className="info-content">{infoContent[index]}</span>
                
              </p>
            ))}
        </div>
        {data.map((item, index) => (
        <Col key={index}>
          <Card data={{ ...item, index }} onFeedbackSubmit={handleFeedbackSubmit} />
          <div className='comment-box'>
            
          <Form>
          <Form.Label size="lg">Which concepts did the article miss or get wrong? (Check all that apply)</Form.Label>
      
          <Form.Check type='checkbox' onChange={(event) => handleConceptChange(index, event)} disabled={relevantConcept} label="Patient / Population / Problem" id="Patient/Population/Problem" />
          <Form.Check type='checkbox' onChange={(event) => handleConceptChange(index, event)} disabled={relevantConcept} label="Intervention / Exposure" id = "Intervention/Exposure"/>
          <Form.Check type='checkbox' onChange={(event) => handleConceptChange(index, event)} disabled={relevantConcept} label="Comparison" id = "Comparison"/>
          <Form.Check type='checkbox' onChange={(event) => handleConceptChange(index, event)} disabled={relevantConcept} label="Outcome" id = "Outcome"/>
          <Form.Check type='checkbox' onChange={(event) => handleConceptChange(index, event)} disabled={relevantConcept} label="Study Design / Research Type" id = "Study Design/Research Type"/>
          <Form.Check type='checkbox' onChange={(event) => handleConceptChange(index, event)} disabled={relevantConcept} label="Setting / Location" id="Setting/Location" />
          <Form.Check type='checkbox' onChange={(event) => handleConceptChange(index, event)} disabled={relevantConcept} label="Phenomenon of Interest" id="Phenomenon of Interest"/>
          <Form.Check type='checkbox' onChange={(event) => handleConceptChange(index, event)} disabled={relevantConcept} label="Evaluation" id="Evaluation"/>
          <Form.Check type='checkbox' onChange={(event) => handleConceptChange(index, event)} disabled={relevantConcept} label="The article captured all relevant concepts" id="The article captured all relevant concepts"/>
          
          <Form.Check type='checkbox' label="Other" onChange={(e) => {
            const isChecked = e.target.checked;
            const updatedValues = [...showOtherInput]; // Assuming showOtherInput is an array
            updatedValues[index] = isChecked;
            setShowOtherInput(updatedValues);
          }}/>
          {showOtherInput[index] ? (
            <Form.Control
              as="textarea"
              rows={3}
              value={otherInputs[index] !== undefined ? otherInputs[index] : 'N/A'}
              onChange={(event) => handleOtherInputChange(index, event)}
              placeholder="Please list any other concerns"
            />
          ) : null}
          <br />
          <br />
          </Form>
          <Form>
              <Form.Group className="mb-3">
                <Form.Label size="lg">Please list the specific key terms that were not captured in the article that should have been (e.g., terms describing or related to the population, intervention, study design, etc.)</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={3}
                    value={commentInputs[index] !== undefined ? commentInputs[index] : 'N/A'}
                    onChange={(event) => handleInputChange(index, event)}
                    placeholder="Please list the specific key terms"
                  />
                <br />
              </Form.Group>
            </Form>
          </div>
        </Col>
      ))}
          <div className='comment-box'>
          <Form>
            <Form.Group as={Row}>
              <Form.Label >
                Relevancy Rating
              </Form.Label>
              <Col>
                <RangeSlider
                  value={relevancy_rating}
                  onChange={e => setRelevancyRating(e.target.value)}
                  min={1}
                  max={5}
                />
              </Col>
            </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label size="lg">Why have you choosen this relevancy rating</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={3}
                    value={finalInputs}
                    onChange={(event) => handleFinalInputChange(event)}
                    placeholder="Please list the reason for the certain relevancy rating"
                  />
                <br />
              </Form.Group>
            </Form>
            <div className="centered-button-container"> 
            <Button variant="outline-primary" size="lg" onClick={submitFeedback}>Submit</Button>
            &emsp;
            {/* <Button variant="outline-secondary" size="lg" onClick={handleRefresh}>Refresh</Button> */}
            {submitPressed && (
              <Button variant="outline-secondary" size="lg" onClick={handleRefresh}>New Query</Button>
            )}
            </div>
        </div>
      </div>
      ) : null}
      </>
    );
  }